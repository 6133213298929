<template>
  <svg
    width="26"
    height="2"
    viewBox="0.129 0 25.871 18.564"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="w-6 h-6"
  >
    <defs></defs>
    <path d="M1 1H25" stroke-width="1.5" stroke-linecap="round"></path>
    <path d="M 1.119 9.281 L 25.119 9.281" stroke-width="1.5" stroke-linecap="round"></path>
    <path d="M 1.061 17.491 L 25.061 17.491" stroke-width="1.5" stroke-linecap="round"></path>
  </svg>
</template>
